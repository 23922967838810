// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-examples-js": () => import("./../src/pages/contact/examples.js" /* webpackChunkName: "component---src-pages-contact-examples-js" */),
  "component---src-pages-contact-file-upload-js": () => import("./../src/pages/contact/file-upload.js" /* webpackChunkName: "component---src-pages-contact-file-upload-js" */),
  "component---src-pages-contact-index-js": () => import("./../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thanks-js": () => import("./../src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-humour-index-js": () => import("./../src/pages/humour/index.js" /* webpackChunkName: "component---src-pages-humour-index-js" */),
  "component---src-pages-recipes-index-js": () => import("./../src/pages/recipes/index.js" /* webpackChunkName: "component---src-pages-recipes-index-js" */),
  "component---src-templates-content-page-js": () => import("./../src/templates/content-page.js" /* webpackChunkName: "component---src-templates-content-page-js" */),
  "component---src-templates-humour-js": () => import("./../src/templates/humour.js" /* webpackChunkName: "component---src-templates-humour-js" */),
  "component---src-templates-index-page-js": () => import("./../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-recipe-js": () => import("./../src/templates/recipe.js" /* webpackChunkName: "component---src-templates-recipe-js" */)
}

